import { StringNumber } from '@/apis';
export enum WithdrawlStatus {
  AUDITING = 'auditing',
  AUDITED = 'audited',
  SUCCESS = 'success',
  FAILED = 'failed'
}

export interface Withdrawal {
  id: number
  withdrawalNumber: string
  createdAt: string
  receiveBankCode: string
  receiveBankAccount: string
  receiveName: string
  appliedAmount: StringNumber
  actualAmount: StringNumber
  status: WithdrawlStatus
}

export interface WithdrawalOverview {
  applicableAmount: StringNumber
  appliedAmout: StringNumber
  feePercent: StringNumber
}

export type PartialWithdrawal = Partial<Withdrawal>;
