import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dfaf1152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "會員詳細",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, {
            label: "證件圖片",
            width: 10
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"auto","height":"300px"},
                "preview-src-list": [_ctx.data?.review?.idPhotoPath],
                src: _ctx.data?.review?.idPhotoPath,
                fit: "contain"
              }, null, 8, ["preview-src-list", "src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "會員編號" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.memberNo ? _ctx.data.memberNo : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "暱稱" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.username ? _ctx.data.username : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "信箱" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.email ? _ctx.data.email : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "生日" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.birthday ? _ctx.formatLocalTime(_ctx.data.birthday,'YYYY-MM-DD') : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "驗證方式" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data?.review?.verifiedType ? _ctx.setVerifiedType(_ctx.data?.review?.verifiedType) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "目前狀態" }, {
            default: _withCtx(() => [
              (_ctx.data?.review?.verifiedStatus)
                ? (_openBlock(), _createBlock(_component_el_tag, {
                    key: 0,
                    type: _ctx.setVerifiedStatus(_ctx.data?.review?.verifiedStatus).tagColor
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.setVerifiedStatus(_ctx.data?.review?.verifiedStatus).text), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, " - "))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}