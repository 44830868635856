import { StringNumber } from '@/apis';

export enum CampaignMedium {
  CPA = 'cpa',
  CPC = 'cpc',
  CPS = 'cps'
}

export enum CampaignType {
  TEXT = 'text',
  VIDEO = 'video',
  DISPLAY_AD = 'display_ad'
}

export interface LevelCommission {
  id: number
  level: number
  threshold: StringNumber
  percentage: StringNumber
}

export interface Campaign {
  id: number
  utmMedium: CampaignMedium
  utmCampaign: string
  utmFormat: CampaignType
  utmSize: string
  startedAt: string
  endedAt: string
  initCommissionPercent: StringNumber
  rebillCommissionPercent: StringNumber
  levelCommissions: LevelCommission[]
}

export interface CampaignCommission {
  id: number
  utmSource: string
  utmCampaign: string
  utmSize: string
  initSubscriptionAmount: StringNumber
  renewalAmount: StringNumber
  subscriptionLevelAmount: StringNumber
  renewalLevelAmount: StringNumber
  initCommissionPercent: StringNumber
  rebillCommissionPercent: StringNumber
  levelCommissions: LevelCommission
}

export interface CampaignCommissionOverview {
  subscriptionAmount: StringNumber
  commissionAmount: StringNumber
  feeAmount: StringNumber
  actualCommissionAmount: StringNumber
}

export type PartialCampaign = Partial<Campaign>;
export type PartialCampaignCommission = Partial<CampaignCommission>;
