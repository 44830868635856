
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: null
    },
    clickSearch: {
      type: Function,
      required: true
    },
    inputWidth: {
      type: String,
      default: '230px'
    }
  },
  setup(props) {
    const keyword = ref('');

    const searchKeyword = () => {
      props.clickSearch(keyword.value.trim());
    };

    return {
      keyword,
      searchKeyword
    };
  }

});
