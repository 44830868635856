
import { defineComponent, ref, watch } from 'vue';
import { formatLocalTime } from '@/utils/format-time';
import { ElMessage } from 'element-plus';
import {
  useUsersReview, useUpdateUserReview
} from '@/composables/api';
import {
  ResponseError,
  VERIFY_SUCCESSFULLY_TEXT,
  VERIFY_FAILED_TEXT
} from '@/apis';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { setVerifiedType } from '../users/utils';
import { VerifiedType, VerifiedState } from '@/interfaces';
const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: '證件類型',
    placeholder: '請選擇',
    options: [
      {
        label: '身份證',
        value: `${VerifiedType.ID_CARD}`
      },
      {
        label: '健保卡',
        value: `${VerifiedType.IC_CARD}`
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const pageSize = ref(15);
    const keyword = ref('');
    const verifiedType = ref<VerifiedType>();
    const showDialog = ref(false);
    const statusIndex = ref(undefined);
    const carousel = ref<HTMLFormElement>();
    const { data, isLoading, isFetching, refetch } = useUsersReview({ page, pageSize, keyword, verifiedType });
    const { mutate: updateUserReview } = useUpdateUserReview();
    const currentCarouselIndex = ref<number>();
    const nextCarouselIndex = ref<number>();
    const currentUserId = ref<number>();
    const btnLoading = ref(false);

    watch(() => data.value, (newValue) => {
      if (!newValue?.data.length) {
        showDialog.value = false;
      }
    }, { immediate: true });

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event) => {
      // mutate ref
      verifiedType.value = event[0];
      searchKeyword(keyword.value);
    };

    const nextToCarousel = (index) => {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        carousel.value?.setActiveItem(index);
      }, 300);
    };

    watch(() => [currentCarouselIndex.value, showDialog.value], () => {
      console.log('watch', currentCarouselIndex.value, showDialog.value);
      currentUserId.value = data.value?.data[currentCarouselIndex.value]?.userId;
    }, { immediate: true });

    const setIndex = (index: number) => {
      currentCarouselIndex.value = index;
      if (data.value.data[index + 1]) {
        nextCarouselIndex.value = index + 1;
      } else {
        nextCarouselIndex.value = null;
      }
    };

    const fetchReview = (isVerify: VerifiedState) => {
      btnLoading.value = true;
      updateUserReview({
        userId: `${currentUserId.value}`,
        data: {
          verifiedStatus: isVerify
        }
      }, {
        onSuccess() {
          setIndex(currentCarouselIndex.value);
          refetch.value().then(() => {
            btnLoading.value = false;
            nextToCarousel(currentCarouselIndex.value - 1);// refetch 後會自動觸發changeCarousel到下2頁,所以需減1
          });
          ElMessage.success(isVerify === 'verified' ? VERIFY_SUCCESSFULLY_TEXT : VERIFY_FAILED_TEXT);
        },
        onError(error: ResponseError) {
          btnLoading.value = false;
          ElMessage.error(error.response?.data.error.message);
        }
      });
    };

    const onVerify = (scope: any) => {
      showDialog.value = true;
      setIndex(scope.$index);
      nextToCarousel(scope.$index);
    };

    const changeCarousel = (index: number) => {
      setIndex(index);
    };

    const closeDialog = () => {
      refetch.value();
    };

    return {
      page,
      pageSize,
      data,
      isLoading,
      isFetching,
      refetch,
      statusIndex,
      FILTER_OPTIONS,
      searchKeyword,
      formatLocalTime,
      handleFilterChange,
      // start here
      setVerifiedType,
      verifiedType,
      showDialog,
      fetchReview,
      onVerify,
      carousel,
      changeCarousel,
      currentCarouselIndex,
      nextCarouselIndex,
      closeDialog,
      btnLoading
    };
  }
});
