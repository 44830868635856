
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { getUser } from '@/apis';
import { formatLocalTime } from '@/utils/format-time';
import { PartialUser } from '@/interfaces/User';
import { setVerifiedStatus, setVerifiedType } from './utils';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialUser & {} = {
  id: null,
  memberNo: null,
  username: null,
  password: null,
  email: null,
  birthday: null,
  createdAt: null,
  review: null
};

export default defineComponent({
  setup() {
    const userId = useRoute().params.id as string;
    const data = ref(DEFAULT_FORM_VALUES);
    onMounted(async() => {
      const res = await getUser({ userId });
      data.value = res.data;
    });

    return {
      data,
      setVerifiedType,
      formatLocalTime,
      setVerifiedStatus
    };
  }
});
