import { VerifiedType, VerifiedStatus } from './index';

export interface UserReview {
  userId: number
  submissionedAt: Date
  reviewedAt: Date
  memberNo: string
  username: string
  verifiedStatus: VerifiedStatus
  verifiedType: VerifiedType
  idPhotoPath: string
}
export enum VerifiedState {
  VERIFIED='verified',
  FAILED='failed'
}
export interface VerityUserReview {
  verifiedStatus: VerifiedState
}

export type PartialUserReview = Partial<UserReview>;
export type PartialVerityUserReview = Partial<VerityUserReview>;
