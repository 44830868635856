
export enum VerifiedStatus {
    VERIFIED = 'verified',
    VERIFYING = 'verifying',
    NOT_VERIFIED = 'not_verified',
    FAILED = 'failed'
}

export enum VerifiedType {
    ID_CARD = 'id_card',
    IC_CARD = 'ic_card',
    CREDIT_CARD = 'credit_card',
    ADMIN_USER = 'admin_user',
    NFT = 'nft'
}

export interface Review {
    id: number
    submissionedAt: Date
    reviewedAt: Date
    memberNo: string
    username: string
    verifiedStatus: VerifiedStatus
    verifiedType: VerifiedType
    idPhotoPath: string
}

export type PartialReview = Partial<Review>;
